<template>
  <div class="footer">
    <div class="container">
      <div class="row">

        <div class="col-12 col-sm-5 footer__logo">
          <img src="../assets/images/footer_logo.svg" alt="">
          <p>Copyright © 2017 - 2023 ООО "EXIM AGRO GROUP"</p>
        </div>

        <div class="col-2 d-flex flex-column justify-content-between footer__socials">
          <div class="d-flex flex-wrap align-items-center justify-content-around">

            <div v-if="footer['socials']['instagram']" class="col-6 d-flex justify-content-center">
              <a
                  class="mb-3"
                  target="_blank"
                  :href="footer['socials']['instagram']"
              >
                <img src="../assets/images/instagram.png" alt="">
              </a>
            </div>
            <div v-if="footer['socials']['telegram']" class="col-6 d-flex justify-content-center">
              <a
                  class="mb-3"
                  target="_blank"
                  :href="footer['socials']['telegram']"
              >
                <img src="../assets/images/telegram.png" alt="">
              </a>
            </div>
            <div v-if="footer['socials']['facebook']" class="col-6 d-flex justify-content-center">
              <a
                  class="mb-3"
                  target="_blank"
                  :href="footer['socials']['facebook']"
              >
                <img src="../assets/images/facebook.png" alt="">
              </a>
            </div>
            <div v-if="footer['socials']['whatsapp']" class="col-6 d-flex justify-content-center">
              <a
                  class="mb-3"
                  target="_blank"
                  :href="footer['socials']['whatsapp']"
              >
                <img src="../assets/images/whatsapp.png" alt="">
              </a>
            </div>
            <div v-if="footer['socials']['vk']" class="col-6 d-flex justify-content-center">
              <a
                  class="mb-3"
                  target="_blank"
                  :href="footer['socials']['vk']"
              >
                <img src="../assets/images/vk.svg" alt="">
              </a>
            </div>
            <div v-if="footer['socials']['linkedin']" class="col-6 d-flex justify-content-center">
              <a
                  class="mb-3"
                  target="_blank"
                  :href="footer['socials']['linkedin']"
              >
                <img src="../assets/images/linkedin.png" alt="">
              </a>
            </div>

          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-5 ">
          <div class="footer__tel">
            <span><img src="../assets/images/phone.svg" alt=""></span>
            <div class="d-flex flex-lg-row flex-column">
              <a :href="`tel:${footer['phone']}`">{{ footer['phone'] }}</a>
              <a :href="`tel:${footer['phone2']}`">{{ footer['phone2'] }}</a>
            </div>
          </div>

          <div class="footer__email">
            <span><img src="../assets/images/mail.svg" alt=""></span>
            <a :href="`mailto:${footer['email']}`">{{ footer['email'] }}</a>
          </div>

          <div class="footer__address">
            <span><img src="../assets/images/footer-location.svg" alt=""></span>
            <span>{{ getPropertyLang(footer['address']) }}</span>
          </div>
        </div>

      </div>

      <div class="footer__socials-sm d-flex align-items-center justify-content-between mt-4">
        <a
            target="_blank"
            :href="footer['socials']['instagram']"
        >
          <img src="../assets/images/instagram.png" alt="">
        </a>
        <a
            target="_blank"
            :href="footer['socials']['telegram']"
        >
          <img src="../assets/images/telegram.png" alt="">
        </a>
        <a
            target="_blank"
            :href="footer['socials']['facebook']"
        >
          <img src="../assets/images/facebook.png" alt="">
        </a>
        <a
            target="_blank"
            :href="footer['socials']['whatsapp']"
        >
          <img src="../assets/images/whatsapp.png" alt="">
        </a>
        <a
            target="_blank"
            :href="footer['socials']['vk']"
        >
          <img src="../assets/images/vk.svg" alt="">
        </a>
        <a
            target="_blank"
            :href="footer['socials']['linkedin']"
        >
          <img src="../assets/images/linkedin.png" alt="">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {getPropertyLang} from "@/utils/getPropertyLang";

export default {
  name: "FooterSection",
  props: {
    footer: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    getPropertyLang,
  }
}
</script>

<style lang="scss" scoped>

.footer {
  padding: 80px 0 60px;
  background-color: #03262c;
  //background-color: #5FD577;

  &__logo {
    img {
      width: 300px;
      margin-bottom: 26px;
    }

    p {
      font-weight: 300;
      font-size: 13.987px;
      line-height: 17px;
      color: #fff;
    }
  }

  img {
    margin-right: 0.5rem;
  }

  a {
    color: #fff;
    text-decoration: none;
    font-weight: 300;
    font-size: 16.011px;
    line-height: 19px;
  }

  &__tel {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    a {
      margin-right: 0.5rem;
    }
  }

  &__email {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__address {
    display: flex;
    align-items: start;

    span {
      color: #fff;
      text-decoration: none;
      font-weight: 300;
      font-size: 16.011px;
      line-height: 19px;
    }
  }

  &__socials-sm {
    display: none !important;
  }

  &__socials {
    a {
      width: 32px;
      height: 32px;
      transition: .3s;

      &:hover {
        transform: scale(1.2) !important;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

@media only screen and (max-width: 767px) {

  .footer {
    padding: 50px 0;
    background-color: #03262c;

    &__logo {
      img {
        width: auto;
        margin-bottom: 26px;
      }
    }

    &__socials {
      display: none !important;
    }

    &__socials-sm {
      display: flex !important;
      margin: 0 auto;
      //width: 80%;

      & a {
        width: 32px;
        height: 32px;
        transition: .3s;

        &:hover {
          transform: scale(1.2) !important;
        }

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

}

</style>
