<template>
  <div>

    <!--  INTRO SECTION  -->
    <IntroSection
        :intro="response.head"
        :navbar="response['settings']"
    />

    <!--  ABOUT COMPANY SECTION  -->
    <AboutCompanySection
        id="about-company"
        :abouts="response.abouts"
    />

    <WorldFoodSection
        id="promo"
        :background="response.promo"
    />

    <!--  CATALOG SECTION  -->
    <CatalogSection
        id="catalog"
        :categories="response.categories"
        :product="response.products"
    />

    <!--  ADVANTAGES SECTION  -->
    <AdvantagesSection
        :advantage="response['advantages']"
        id="advantages"
    />

    <!--  NEWS SECTION  -->
    <NewsSection
        :news="lastThreeNews"
    />

    <!--  GALLERY SECTION  -->
    <GallerySection
        :gallery="response['galleries']"
    />

    <!--  CERTIFICATES SECTION  -->
    <CertificatesSection
        v-if="response['certs']"
        :certificates="response['certs']"
    />

    <!--  FORM SECTION  -->
    <FormSection id="form-section"/>

    <!--  FOOTER SECTION  -->
    <FooterSection
        :footer="response['settings']"
    />

  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import IntroSection from "@/components/IntroSection";
import AboutCompanySection from "@/components/AboutCompanySection";
import CatalogSection from "@/components/CatalogSection";
import AdvantagesSection from "@/components/AdvantagesSection";
import GallerySection from "@/components/GallerySection";
import FormSection from "@/components/FormSection";
import FooterSection from "@/components/FooterSection";
import axios from "axios";
import WorldFoodSection from "@/components/WorldFoodSection";
import CertificatesSection from "@/components/CertificatesSection.vue";
import NewsSection from "@/components/NewsSection.vue";

export default {
  name: 'AppMain',
  components: {
    CertificatesSection,
    WorldFoodSection,
    IntroSection,
    AboutCompanySection,
    CatalogSection,
    AdvantagesSection,
    GallerySection,
    FormSection,
    FooterSection,
    NewsSection,
    TheHeader
  },

  props: {
    response: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      // response: [],
      lastThreeNews: [],
    }
  },

  async mounted() {
    this.pushLastNews()
    this.toggleBannerBlock()
  },

  methods: {

    pushLastNews() {
      if (window.innerWidth > 991) {
        this.lastThreeNews = this.response['articles'].slice(-3)
      } else if (window.innerWidth > 576) {
        this.lastThreeNews = this.response['articles'].slice(-2)
      } else if (window.innerWidth < 575) {
        this.lastThreeNews = this.response['articles'].slice(-1)
      }
    },

    toggleBannerBlock() {
      const promo = document.getElementById('promo')
      if (this.response.promo?.image?.web || this.response.promo?.image?.mobile) {
        promo.style.display = 'block'
      } else {
        promo.style.display = 'none'
      }
    }

  }
}
</script>

<style lang="scss">
* {
  font-family: Museo, sans-serif;
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

.btn {
  &:active {
    background: none !important;
  }

  &:focus {
    box-shadow: none !important;
    background: none !important;
  }

  &:focus-visible {
    outline: none !important;
  }
}

.modal-header {
  display: none !important;
  border-bottom: none !important;
}

@media only screen and (max-width: 575px) {

  .modal-body {
    padding: 4rem 1rem 1rem !important;
  }
}
</style>
