<template>
  <div class="about">
    <div v-if="abouts && abouts.about" class="container">
      <h2 class="about__title">{{ getPropertyLang(abouts.about.title) }}</h2>
      <div class="about__info">
        <p>{{ getPropertyLang(abouts.about.description) }}</p>
      </div>

      <div class="about__slider">
        <swiper class="swiper" :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="(image, index) in abouts.images" :key="index">
            <img :src="image.image" alt="" :key="index">
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>


</template>

<script>
import {getPropertyLang} from "@/utils/getPropertyLang";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: "AboutCompanySection",
  components: {
    Swiper,
    SwiperSlide
  },

  props: {
    abouts: {
      type: Object,
      default: () => ({})
    },
  },

  data() {
    return {
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 30,
        loop: true,
        autoplay: {
          delay: 2000,
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          576: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        }
      },
    }
  },

  methods: {
    getPropertyLang,

    imageMaker(image) {
      return process.env.VUE_APP_URL + image
    },
  }
}
</script>

<style lang="scss" scoped>

::v-deep .swiper-wrapper {
  display: flex !important;
  align-items: center !important;
}

.about {
  background-color: #FFFCF3;
  padding: 105px 0;

  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    margin-bottom: 38px;
  }

  &__info p {
    font-weight: 300;
    font-size: 18px;
    line-height: 144%;
    text-align: center;
    color: #1E1E1E;
    margin-bottom: 28px;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      width: 90%;
      height: auto;
      object-fit: contain;
    }
  }
}

@media only screen and (max-width: 991px) {

  .about {
    padding: 80px 0;
  }

}

@media only screen and (max-width: 767px) {

  .about {
    padding: 60px 0;
  }

}

@media only screen and (max-width: 575px) {

  .about {
    padding: 40px 0;
  }

}

</style>
