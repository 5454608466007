import Vue from "vue";
import VueLang from "vue-i18n"
import ru from './locales/ru.json'
import en from './locales/en.json'

Vue.use(VueLang)
const language = localStorage.getItem('locale') || 'ru'
const i18n = new VueLang({
  locale: language,
  messages: {
    ru,
    en
  }
})

export default i18n;
