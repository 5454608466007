<template>
  <div class="header">
    <div class="container">
      <div class="position-relative">

        <!--   NAVBAR DESKTOP   -->
        <nav class="navbar navbar__desktop">

          <div class="navbar__logo">
            <router-link :to="{name: 'home'}">
              <img :src="navbar.logo" alt="">
            </router-link>
          </div>

          <ul class="navbar__links d-flex align-items-center">
            <li>
              <a
                  @click="selectNavigation"
                  href="#catalog"
              >
                {{ $t('catalog') }}
              </a>
            </li>
            <li>
              <a
                  @click="selectNavigation"
                  href="#about-company"
              >
                {{ $t('about_company') }}
              </a>
            </li>
            <li>
              <a
                  @click="selectNavigation"
                  href="#advantages"
              >
                {{ $t('advantages') }}
              </a>
            </li>
          </ul>

          <div class="navbar__tel-lang">
            <a href="tel:+998943737300" class="navbar__tel-btn">
              <img src="../assets/images/phone.svg" alt="">
              <span>{{ navbar.phone }}</span>
            </a>
            <div class="navbar-whatsapp" v-if="navbar['socials']['whatsapp']">
              <a
                  target="_blank"
                  :href="navbar['socials']['whatsapp']"
              >
                <img src="../assets/images/whatsapp.png" alt="">
              </a>
            </div>
            <div class="d-flex">
              <b-button
                  class="navbar__lang-btn"
                  :class="{'active': $i18n.locale === lang}"
                  :key="lang+index"
                  @click="changeLocale(lang)"
                  v-for="(lang,index) in langs"
              >
                {{ langUpperCase(lang) }}
              </b-button>
            </div>
          </div>

        </nav>

        <!--   NAVBAR MOBILE   -->
        <nav class="navbar navbar__mobile">

          <div class="navbar__logo">
            <router-link :to="{name: 'home'}"
                         @click="selectNavigation"
            >
              <img src="../assets/images/Logo.svg" alt="">
            </router-link>
          </div>

          <!--   HAMBURGER BUTTON   -->
          <button class="menu"
                  @click="openMenu"
                  :class="menuClass"
                  :aria-expanded="ariaExpanded"
                  aria-label="Main Menu"
          >
            <svg width="100" height="100" viewBox="0 0 100 100">
              <path class="line line1"
                    d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"/>
              <path class="line line2" d="M 20,50 H 80"/>
              <path class="line line3"
                    d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"/>
            </svg>
          </button>

        </nav>

      </div>

      <!--    NAVBAR MOBILE CONTENT    -->
      <div
          class="navbar__mobile-content1"
          :class="{'active': isOpened}"
      >
        <ul class="navbar__links d-flex align-items-center">
          <li><a href="#catalog" @click="selectNavigation">{{ $t('catalog') }}</a></li>
          <li><a href="#about-company" @click="selectNavigation">{{ $t('about_company') }}</a></li>
          <li><a href="#advantages" @click="selectNavigation">{{ $t('advantages') }}</a></li>
        </ul>

        <div class="navbar__tel-lang">
          <div class="navbar__lang-buttons">
            <b-button
                class="navbar__lang-btn"
                :class="{'active': $i18n.locale === lang}"
                :key="lang+index"
                @click="changeLocale(lang)"
                v-for="(lang,index) in langs"
            >
              {{ langUpperCase(lang) }}
            </b-button>
          </div>
          <div class="d-flex align-items-center">
            <a href="#" class="navbar__tel-btn">
              <img src="../assets/images/phone.svg" alt="">
              <span>+998 94 373 73 00</span>
            </a>
            <div class="navbar-whatsapp-mobile ms-4">
              <a
                  target="_blank"
                  :href="navbar['socials']['whatsapp']"
              >
                <img src="../assets/images/whatsapp.png" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "TheHeader",
  data() {
    return {
      isOpened: false,
      menuClass: '',
      ariaExpanded: false,
      scrollPosition: null,
      langs: [
        'en', 'ru'
      ],
    }
  },

  props: {
    navbar: {
      type: Object,
      default: () => ({})
    }
  },

  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },

  methods: {

    openMenu() {
      if (!this.menuClass) {
        this.menuClass = ''
        this.isOpened = false
        this.ariaExpanded = true
      } else {
        this.menuClass = 'opened'
        this.ariaExpanded = false
        this.isOpened = true
      }
    },

    selectNavigation() {
      this.isOpened = false
      this.menuClass = ''
      this.$router.push('/')
    },

    updateScroll() {
      this.scrollPosition = window.scrollY
    },

    langUpperCase(text) {
      return text.toUpperCase()
    },

    changeLocale(lang) {
      this.$i18n.locale = lang
      localStorage.setItem('locale', this.$i18n.locale)
      this.isOpened = false
      this.menuClass = ''
    },

    imagePathMaker(image) {
      return process.env.VUE_APP_URL + image
    }
  }
}
</script>

<style lang="scss" scoped>

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 123;
  width: 100%;
  background-color: #03262c;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0;

  &-whatsapp {
    width: 44px;
    height: 44px;
    padding: .5rem;

    & img {
      width: 100%;
      height: 100%;
    }

    &-mobile {
      width: 44px;
      height: 44px;
      padding: .5rem;
      background-color: #5FD577;
      border-radius: .5rem;

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__logo {
    object-fit: contain;
  }

  &__links {
    margin: 0;
    padding: 0 0 0 5rem;

    li {
      padding: 0 1.5rem;

      a {
        text-decoration: none;
        transition: .3s;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #fff;
        cursor: pointer;

        &:hover {
          //text-shadow: 2px 2px 10px #ffffff;
          color: #5FD577;
        }
      }
    }
  }

  &__tel-lang {
    display: flex;
    align-items: center;
  }

  &__tel-btn {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    padding: 14px 18px;
    background-color: #5FD577;
    border-radius: 24px;
    margin-right: 1rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    img {
      object-fit: contain;
      margin-right: 0.3rem;
    }
  }

  &__lang-btn {
    width: 35px;
    height: 35px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0.5rem;
    margin-left: 0.5rem;
    background-color: transparent;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    &:hover {
      background-color: #5FD577 !important;
      color: #fff;
    }

    &.active {
      background-color: #5FD577 !important;
      color: #fff;
    }
  }

  &__mobile {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    z-index: 12311;
  }

  &__mobile-content1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    visibility: hidden;
    transform: translateY(-100%);
    transition: .6s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 15%;

    .navbar__links {
      display: flex;
      flex-direction: column;
      padding: 0;

      li {
        margin-bottom: 1rem;

        a {
          color: #fff;
        }
      }
    }

    .navbar__tel-lang {
      display: flex;
      flex-direction: column;
    }

    .navbar__tel-btn {
      margin: 0;
    }

    .navbar__lang-buttons {
      display: flex;
      margin-bottom: 1rem;

      button {
        margin: 0 0.5rem;
        color: #fff;
      }
    }
  }
}

.change_color {
  background-color: #03262c;

  & .navbar__links span {
    color: #fff;
  }

  & .navbar__lang-btn {
    color: #fff;
  }
}

//  HAMBURGER BUTTON
.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  & svg {
    width: 100%;
    height: 100%;
  }
}

.line {
  fill: none;
  stroke: white;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
  stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}

.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}

.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

@media only screen and (max-width: 1199px) {

  .navbar {
    &__desktop {
      .navbar__links {
        padding: 0;

        li {
          padding: 0 1rem;
        }
      }
    }
  }

}

@media only screen and (max-width: 991px) {

  .change_color .menu svg path {
    stroke: #fff;
  }

  .header {
    height: 80px;
  }

  .navbar {
    &__desktop {
      display: none;
    }

    &__mobile {
      width: 100%;
      visibility: visible;
      transform: translateX(0);
      padding: 18px 0;
    }

    .menu {
      display: flex;
      right: 0;

      &.opened path {
        stroke: #fff;
      }
    }

    &__mobile-content1.active {
      background-color: rgba(0, 0, 0, 0.98);
      visibility: visible;
      transform: translateY(0);

    }
  }

}

@media only screen and (max-width: 767px) {

  .navbar {
    &__mobile-content1 {
      padding-top: 20%;
    }
  }

}

@media only screen and (max-width: 575px) {

  .navbar {
    &__mobile-content1 {
      padding-top: 30%;
    }
  }

}

</style>
