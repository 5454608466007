<template>
  <div class="page-not-found">
    <div class="page-not-found-block">
      <h2>404</h2>
      <p>{{ $t('page_not_found_text') }}</p>
      <router-link to="/">{{ $t('page_not_found_btn') }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style lang="scss" scoped>

.page-not-found {
  height: 100vh;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  & h2 {
    text-align: center;
    font-size: 130px;
    font-weight: 900;
    color: #5FD577;
    margin-bottom: 0;
  }

  & p {
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    color: #5FD577;
  }

  & a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 400px;
    height: 60px;
    border: 4px solid #5FD577;
    color: #5FD577;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    transition: .3s;

    &:hover {
      background-color: #5FD577;
      color: #fff;
      transform: scale(1.1);
    }
  }
}

@media only screen and (max-width: 1199px) {
  .page-not-found {
    & h2 {
      font-size: 100px;
    }

    & p {
      font-size: 40px;
    }

    & a {
      width: 350px;
      font-size: 17px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .page-not-found {
    & h2 {
      font-size: 100px;
    }

    & p {
      font-size: 40px;
    }

    & a {
      width: 350px;
      font-size: 17px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .page-not-found {
    & h2 {
      font-size: 70px;
    }

    & p {
      font-size: 28px;
    }

    & a {
      width: 290px;
      font-size: 16px;
      border: 3px solid;
    }
  }
}

</style>
