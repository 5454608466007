import Vue from 'vue'
import VueRouter from 'vue-router'
import AppMain from "@/views/main/AppMain.vue";
import News from "@/views/news/index.vue";
import _id from "@/views/news/_id.vue";
import PageNotFound from "@/views/404/PageNotFound.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: AppMain
    },
    {
        path: '/news',
        name: 'news',
        component: News
    },
    {
        path: '/news/:id',
        name: 'new',
        component: _id
    },
    {
        path: "/*",
        name: '404',
        component: PageNotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
