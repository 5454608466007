<template>
  <div class="advantages">
    <div class="container">
      <h2 class="advantages__title">{{ $t('advantages') }}</h2>

      <!--   WHEN ITEMS ARE LESS THAN 5   -->
      <div v-if="advantage.length < 5" class="row flex-nowrap overflow-scroll pb-2">

        <div
            v-for="item in advantage"
            class="advantages__card col-9 col-sm-7 col-md-5 col-lg-5 col-xl-3 px-3"
        >
          <img :src="item.image" alt="">
          <span>{{ getPropertyLang(item.title) }}</span>
          <p>{{ getPropertyLang(item.description) }}</p>
        </div>

      </div>

      <!--   WHEN ITEMS ARE MORE THAN 5   -->
      <swiper
          v-else
          class="swiper"
          :options="swiperOption"
      >
        <swiper-slide
            v-for="(item,index) in advantage"
            :key="index"
        >
          <div class="slider__block">
            <img :src="item.image" alt="">
            <span>{{ getPropertyLang(item.title) }}</span>
            <p>{{ getPropertyLang(item.description) }}</p>
          </div>
        </swiper-slide>
        <div v-if="advantage.length > 4" class="swiper-button-prev" slot="button-prev"></div>
        <div v-if="advantage.length > 4" class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import {getPropertyLang} from "@/utils/getPropertyLang";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: "AdvantagesSection",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    advantage: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      swiperOption: {
        spaceBetween: 30,
        // loop: true,
        // autoplay: {
        //   delay: 4000
        // },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          576: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        }
      },
    }
  },
  methods: {
    getPropertyLang,

    imagePathMaker(image) {
      return process.env.VUE_APP_URL + image
    }
  }
}
</script>

<style lang="scss" scoped>

.swiper {
  padding-bottom: 80px;
}

.swiper-button-prev {
  top: 94%;
  left: 80%;

  &::after {
    color: #5FD577;
  }
}

.swiper-button-next {
  top: 94%;
  left: 90%;

  &::after {
    color: #5FD577;
  }
}

.slider__block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  & span {
    display: block;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #1E1E1E;
    margin-top: 38px;
  }

  & p {
    font-weight: 300;
    font-size: 18px;
    line-height: 144%;
    text-align: center;
    color: #999999;
    margin-top: 14px;
  }
}

.advantages {
  position: relative;
  padding: 100px 0 60px;

  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    color: #1E1E1E;
    margin-bottom: 60px;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }

    span {
      display: block;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #1E1E1E;
      margin-top: 38px;
    }

    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 144%;
      text-align: center;
      color: #999999;
      margin-top: 14px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .swiper-button-prev {
    top: 94%;
    left: 75%;
  }

  .swiper-button-next {
    top: 94%;
    left: 90%;
  }
}

@media only screen and (max-width: 767px) {

  .advantages {
    padding: 80px 0 60px;
  }

}

@media only screen and (max-width: 575px) {

  .advantages {
    padding: 60px 0 40px;
  }

}

</style>
