<template>
    <div class="form__section">
        <div class="container">
            <h2 class="form__section_title">{{ $t('feedback_title') }}</h2>
            <span class="form__section_subtitle">{{ $t('feedback_subtitle') }}</span>

            <div>
                <b-form
                    @submit.prevent="onSubmit"
                >

                    <div class="row">
                        <div class="col-xl-3 col-lg-4 col-md-7 mx-auto mb-3 col-sm-8 col-10">
                            <b-form-group class="position-relative">
                                <b-form-input
                                    id="name"
                                    :placeholder="$t('form_name')"
                                    v-model="name"
                                    @focus="checkValidateName"
                                ></b-form-input>
                                <span
                                    v-if="!name && errors.name"
                                    class="validation__error"
                                >{{ $t('validation_error') }}</span>
                            </b-form-group>
                        </div>

                        <div class="col-xl-3 col-lg-4 col-md-7 mx-auto mb-3 col-sm-8 col-10">
                            <b-form-group class="position-relative">
                                <b-form-input
                                    type="number"
                                    id="phone-number"
                                    :placeholder="$t('form_phone')"
                                    v-model="phone"
                                    @focus="checkValidatePhone"
                                ></b-form-input>
                                <span
                                    v-if="!phone && errors.phone"
                                    class="validation__error"
                                >{{ $t('validation_error') }}</span>
                            </b-form-group>
                        </div>

                        <div class="col-xl-3 col-lg-4 col-md-7 mx-auto mb-3 col-sm-8 col-10">
                            <b-form-group>
                                <b-form-input
                                    type="email"
                                    placeholder="Email"
                                    v-model="email"
                                ></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-xl-3 mt-xl-0 mt-lg-3 mb-3 button-block-1">
                            <b-button type="submit" variant="primary" class="submit_btn mx-auto mx-xl-0 d-block">{{
                                    $t('submit_btn')
                                }}
                            </b-button>
                        </div>

                        <div class="col-10 col-sm-8 col-md-7 col-lg-12 col-xl-11 mx-auto mx-lg-0 mb-3">
                            <b-form-group>
                                <b-form-textarea
                                    id="textarea"
                                    v-model="comment"
                                    :placeholder="$t('form_comment')"
                                    rows="3"
                                ></b-form-textarea>
                            </b-form-group>
                        </div>

                        <div class="col-xl-3 mt-xl-0 mt-lg-3 mb-3 button-block-2">
                            <b-button type="submit" variant="primary" class="submit_btn mx-auto mx-xl-0 d-block">{{
                                    $t('submit_btn')
                                }}
                            </b-button>
                        </div>

                        <b-modal
                            id="modal-center" ref="form-modal" centered hide-footer>
                            <div class="modal__content">
                                <p class="modal__text my-4">{{ responseMessage }}</p>
                                <button @click="closeModal" class="modal__close">X</button>
                            </div>
                        </b-modal>

                    </div>

                </b-form>
            </div>

        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "FormSection",
    data() {
        return {
            name: null,
            phone: null,
            email: null,
            comment: null,
            submitted: false,
            responseMessage: "",

            errors: {
                name: false,
                phone: false,
            }

        }
    },
    methods: {
        closeModal() {
            this.$refs['form-modal'].hide()
        },

        onSubmit(event) {
            event.preventDefault()
            this.submitted = false

            this.checkValidateName()
            this.checkValidatePhone()

            if (!(this.errors.name || this.errors.phone)) {
                const formData = new FormData()
                formData.append('name', this.name)
                formData.append('email', this.email)
                formData.append('phone', this.phone)
                formData.append('comment', this.comment)
                axios.post('https://api.eximagro.uz/api/feedback', formData)
                    .then(() => {
                        this.responseMessage = this.$t('form_message_success')
                        this.submitted = true
                        this.$bvModal.show('modal-center')
                    })
                    .catch((error) => {
                        console.error(error)
                        this.responseMessage = this.$t('form_message_error')
                        this.$bvModal.show('modal-center')
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.submitted = false
                        }, 3000)
                        this.name = ''
                        this.phone = ''
                        this.email = ''
                        this.comment = ''
                    })
            }
        },

        checkValidateName() {
            if (!this.name) {
                this.errors.name = true
            } else {
                this.errors.name = false
            }

        },
        checkValidatePhone() {
            if (!this.phone) {
                this.errors.phone = true
            } else {
                this.errors.phone = false
            }
        },
    }
}
</script>

<style lang="scss" scoped>

.modal-body {
    position: relative;
}

.modal__text {
    text-align: center;
    font-size: 18px;
}

.modal__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 6px 12px;
    background-color: red;
    font-size: 16px;
    color: #fff;
    border: none;
    border-radius: .4rem;
}

.form__section {
    padding: 70px 0 150px;

    &_title {
        font-weight: 700;
        font-size: 36px;
        line-height: 43px;
        text-align: center;
        color: #1E1E1E;
        margin-bottom: 21px;
    }

    &_subtitle {
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #999999;
        display: block;
        margin-bottom: 48px;
    }

    input {
        height: 60px;
        background-color: #F8F8F8;
        border: none;
        border-radius: 32px;
        padding: 0.5rem 1.5rem;
    }

    textarea {
        background-color: #F8F8F8;
        border: none;
        border-radius: 32px;
        padding: 0.5rem 1.5rem;
    }

    input::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #999999;
    }

    .submit_btn {
        height: 60px;
        border: none;
        border-radius: 32px;
        padding: 20px 40px;
        background-color: #03262c;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF;
        filter: drop-shadow(0px 12px 20px rgba(95, 213, 119, 0.251));

        &:active {
            background-color: #5FD577 !important;
        }

        &:focus {
            background-color: #5FD577 !important;
        }
    }

    .validation__error {
        position: absolute;
        bottom: -27px;
        color: red;
        font-size: 14px;
        margin: .5rem 0 0 1rem !important;
    }
}

@media screen and (min-width: 1200px) {
    .button-block-2 {
        display: none;
    }
}

@media only screen and (max-width: 1199px) {
    .button-block-1 {
        display: none;
    }

    .button-block-2 {
        display: block;
    }
}

@media only screen and (max-width: 991px) {

    .form__section {
        padding: 60px 0 100px;
    }

}

@media only screen and (max-width: 767px) {

    .form__section {
        padding: 50px 0 80px;
    }

}

@media only screen and (max-width: 575px) {

    .form__section {
        padding: 40px 0 60px;
    }

}

</style>
