<template>
  <div class="catalog">
    <div class="container">
      <h2 class="catalog__title">{{ $t('catalog') }}</h2>

      <div class="catalog__tabs">
        <b-tabs v-model="tabIndex" pills align="center">

          <b-tab
              v-for="category in categories"
              :title="getPropertyLang(category.title)"
          >

            <div class="row catalog__desktop">

              <!--  MODAL BUTTON  -->
              <b-button
                  class="catalog__tabs_content col-6 col-lg-4 col-xl-3 px-3 d-flex flex-column justify-content-start align-items-center"
                  v-for="product in getCategoryProducts(category.id)"
                  v-b-modal="`modal-${product.id}`"
              >
                <div class="catalog__tabs_content_image">
                  <img :src="product.image" alt="">
                  <span v-if="product.status" class="notification__circle"
                        :style="{backgroundColor: product.status.color}">{{
                      getPropertyLang(product.status.title)
                    }}</span>
                </div>
                <span>{{ getPropertyLang(product.title) }}</span>
                <p>{{ product.cost }}</p>

                <!--  MODAL CONTENT  -->
                <b-modal
                    :id="`modal-${product.id}`"
                    centered
                    size="lg"
                    hide-footer
                >
                  <template #default="{hide}">
                    <b-button class="modal-close-btn btn-danger" @click="hide()">X</b-button>
                    <div class="d-flex flex-column flex-sm-row position-relative">
                      <div>
                        <img :src="product.image" alt="">
                      </div>
                      <div class="ms-0 ms-sm-3 w-100">
                        <h3 class="mb-3">{{ getPropertyLang(product.title) }}</h3>
                        <p class="product__info" v-html="getPropertyLang(product.description)"></p>
                      </div>
                    </div>
                  </template>
                </b-modal>
              </b-button>
            </div>

            <div class="catalog__mobile">
              <swiper class="swiper" :options="swiperOption">

                <swiper-slide
                    v-for="product in getCategoryProducts(category.id)"
                >
                  <!--  MODAL BUTTON  -->
                  <div
                      class="catalog__tabs_content col-12 px-3"
                      v-b-modal="`modal-${product.id}`"
                  >
                    <div class="catalog__tabs_content_image">
                      <img :src="product.image" alt="">
                      <span v-if="product.status" class="notification__circle"
                            :style="{backgroundColor: product.status.color}">{{
                          getPropertyLang(product.status.title)
                        }}</span>
                    </div>
                    <span>{{ getPropertyLang(product.title) }}</span>
                    <p>{{ product.cost }}</p>
                  </div>
                </swiper-slide>

                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>

          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {getPropertyLang} from "@/utils/getPropertyLang";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: "CatalogSection",
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    categories: {
      type: Array,
      default: () => ([])
    },

    product: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      tabIndex: 0,
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: {
          delay: 2000
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
        }
      },
    }
  },

  methods: {
    getPropertyLang,

    getCategoryProducts(id) {
      return this.product.filter(product => product['category_id'] === id)
    },

    imagePathMaker(image) {
      return process.env.VUE_APP_URL + image
    }
  }
}
</script>

<style lang="scss" scoped>

.modal-close-btn {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 10;
}

.modal-body img {
  width: 255px;
  height: 300px;
  object-fit: cover;
}

.modal-body h3 {
  font-size: 2rem;
  font-weight: 500;
  width: 90%;
}

.catalog {
  padding: 140px 0 0 0;

  .catalog__mobile {
    display: none;
  }

  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    color: #1E1E1E;
    margin-bottom: 32px;
  }

  ::v-deep .nav-item {
    margin: 0 8px 58px 8px;

    .nav-link {
      background: #FAF9F9;
      color: #999999;
      border-radius: 24px;
      padding: 8px 24px;
    }

    .nav-link.active {
      background: #03262c;
      color: #fff;
    }
  }

  &__tabs_content {
    margin-bottom: 32px;
    background-color: transparent;
    border: none;

    &:hover {
      background-color: transparent;
    }


    &_image {
      position: relative;
      height: 300px;
      background-color: #FFFCF3;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      & img {
        transition: .3s;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      display: block;
      text-align: center;
      color: #999999;
      margin-top: 24px;
    }

    p {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #1E1E1E;
      margin-top: 10px;
    }

    .notification__circle {
      width: 60px;
      height: 60px;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #FFFFFF;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 20px;
      left: 20px;
      margin-top: 0;
    }
  }

  &__tabs_content:hover img {
    transform: scale(1.1);
  }

}

@media only screen and (max-width: 991px) {

  .catalog {
    padding: 100px 0 0 0;
  }

}

@media only screen and (max-width: 767px) {

  .catalog {
    padding: 80px 0 0 0;
  }

}

@media only screen and (max-width: 575px) {

  .modal-body {
    padding: 3rem 1rem 1rem !important;
  }

  .modal-body img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .modal-body h3 {
    text-align: center;
    margin-top: 1rem;
  }

  .catalog {
    padding: 60px 0 0 0;

    ::v-deep .nav-item {
      margin-bottom: 1rem;
    }
  }

  .catalog__desktop {
    display: none;
  }

  .catalog__mobile {
    display: block !important;
  }

  .catalog__tabs_content {
    margin-bottom: 52px;
  }

  .swiper-button-prev {
    background-image: url("../assets/images/slider-arrow-left-black.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 37px;
    height: 20px;
    top: 97%;
    left: 75%;
    transform: translateY(50%);
  }

  .swiper-button-prev::after {
    display: none;
  }

  .swiper-button-next {
    background-image: url("../assets/images/slider-right-arrow-black.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 37px;
    height: 20px;
    top: 97%;
    left: 88%;
    transform: translateY(50%);
  }

  .swiper-button-next::after {
    display: none;
  }

}

</style>
