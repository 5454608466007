<template>
  <!--  <div class="container">-->
  <div class="certificates">
    <h2 class="certificates__title">{{ $t('certificate_title') }}</h2>
    <swiper
        class="swiper"
        :options="swiperOption"
    >
      <swiper-slide
          v-for="(certificate,index) in certificates"
          :key="index"
      >
        <b-button
            v-b-modal="`modal-center-${index}`"
            class="modal__btn"
        >
          <embed :src="`${certificate.image}#toolbar=0&navpanes=0&scrollbar=0`">
        </b-button>

        <b-modal
            ref="certificates__modal"
            :id="`modal-center-${index}`"
            size="md"
            hide-footer
            centered
            title="BootstrapVue"
        >
          <div class="modal-img">
            <embed :src="`${certificate.image}#toolbar=0&navpanes=0&scrollbar=0`">
          </div>
        </b-modal>
      </swiper-slide>
      <div v-if="certificates.length > 4" class="swiper-button-prev" slot="button-prev"></div>
      <div v-if="certificates.length > 4" class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
  <!--  </div>-->
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: "CertificatesSection",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    certificates: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      swiperOption: {
        spaceBetween: 30,
        // loop: true,
        // autoplay: {
        //   delay: 4000
        // },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          576: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
          1400: {
            slidesPerView: 5,
          }
        }
      },
    }
  },
  methods: {
    imagePathMaker(image) {
      return process.env.VUE_APP_URL + image
    }
  }
}
</script>

<style lang="scss" scoped>

.modal-img {
  width: 300px;
  height: 500px;
  margin: auto;

  embed {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.modal__btn {
  background-color: transparent;
  border: none;
  //width: 100%;

  & embed {
    width: 100%;
    height: 350px;
    pointer-events: none;
    object-fit: contain;
  }

  img {
    width: 100%;
    height: 300px;
    max-height: 100%;
    object-fit: contain;
  }

  &:hover {
    background-color: transparent;
    border: none;
  }
}

.certificates {
  //padding: 4rem 0;

  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    color: #1E1E1E;
  }

  & .swiper {
    height: 500px;
    padding: 4rem 4rem 5rem;

    .swiper-slide {
      height: 100%;

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .swiper-button-prev {
      top: 94%;
      left: 80%;

      &::after {
        color: #5FD577;
      }
    }

    .swiper-button-next {
      top: 94%;
      left: 90%;

      &::after {
        color: #5FD577;
      }
    }
  }
}

@media only screen and (max-width: 1399px) {
  .certificates {
    & .swiper {
      height: 500px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .certificates {
    & .swiper {
      .swiper-button-prev {
        left: 75%;
      }

      .swiper-button-next {
        left: 85%;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .certificates {
    & .swiper {
      .swiper-button-prev {
        left: 70%;
      }

      .swiper-button-next {
        left: 85%;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .certificates {
    & .swiper {
      .swiper-button-prev {
        left: 65%;
      }

      .swiper-button-next {
        left: 85%;
      }
    }
  }
}


</style>
