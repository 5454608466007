<template>
  <div class="news">
    <div class="container">
      <h2 class="news__title">{{ $t('news_title') }}</h2>
      <div class="row">
        <div
            v-for="item in news"
            :key="item.id"
            class="news__block col-12 col-sm-6 col-lg-4 px-2 mb-5"
        >
          <img :src="item.image" alt="">
          <span class="news__block-created">{{ item['created_at'] }}</span>
          <h4 class="news__block-title">{{ getPropertyLang(item['title']) }}</h4>
          <router-link
              class="news__block-link"
              :to="{name: 'new', params:{id: item.id}}"
          >
            {{ $t('more_details') }}
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import TheHeader from "@/components/TheHeader.vue";
import axios from "axios";
import {getPropertyLang} from "@/utils/getPropertyLang";

export default {
  name: "index",
  components: {
    TheHeader
  },
  data() {
    return {
      header: {},
      news: []
    }
  },

  async mounted() {
    await this.getAllInfo()
  },

  methods: {
    async getAllInfo() {
      try {
        const {data} = await axios.get('https://api.eximagro.uz/api')
        if (data) {
          this.header = data.settings
          this.news = data['articles']
          this.setDynamicFavicon(data.settings.icon)
        }
      } catch (e) {
        console.error(e)
      }
    },

    getPropertyLang,

    imagePathMaker(image) {
      return process.env.VUE_APP_URL + image
    },

    setDynamicFavicon(image) {
      if (image) {
        const favicon = document.getElementById('favicon')
        favicon.href = image
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.news {
  padding: 100px 0 40px;
}

.header__block {
  background-color: #ddfade;
}

.news {

  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    color: #1E1E1E;
    margin-bottom: 40px;
  }

  &__block {
    & img {
      width: 100%;
      height: 220px;
      object-fit: cover;
    }

    &-created {
      display: block;
      font-size: .8rem;
      color: #999999;
      margin-top: .75rem;
    }

    &-title {
      font-size: 1.2rem;
      font-weight: 500;
    }

    &-link {
      font-size: 1rem;
      text-decoration: none;
      color: #5FD577;
    }
  }
}
</style>
