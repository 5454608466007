<template>
  <div class="container">
    <h2 class="news__title">{{ $t('news_title') }}</h2>
    <div class="news d-flex align-items-start justify-content-lg-start">
      <div
          v-for="item in news"
          :key="item.id"
          class="news__block col-12 col-sm-6 col-lg-4 px-2"
      >
        <img :src="item.image" alt="">
        <span class="news__block-created">{{ item['created_at'] }}</span>
        <h4 class="news__block-title">{{ getPropertyLang(item['title']) }}</h4>
        <router-link class="news__block-link" :to="{name: 'new', params: {id: item.id}}">{{ $t('more_details') }}</router-link>
      </div>
    </div>
    <div class="news__view-all">
      <router-link :to="{name: 'news'}">{{ $t('all_news') }}</router-link>
    </div>
  </div>
</template>

<script>
import {getPropertyLang} from "@/utils/getPropertyLang";

export default {
  name: "NewsSection",
  props: {
    news: {
      type: Array,
      default: () => ([])
    }
  },

  methods: {
    getPropertyLang,

    imagePathMaker(image) {
      return process.env.VUE_APP_URL + image
    }
  },
}
</script>

<style lang="scss" scoped>

.news {
  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    color: #1E1E1E;
    margin-bottom: 60px;
  }

  &__block {
    & img {
      width: 100%;
      height: 220px;
      object-fit: cover;
    }

    &-created {
      display: block;
      font-size: .8rem;
      color: #999999;
      margin-top: .75rem;
    }

    &-title {
      font-size: 1.2rem;
      font-weight: 500;
    }

    &-link {
      font-size: 1rem;
      text-decoration: none;
      color: #5FD577;
    }
  }

  &__view-all {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

    & a {
      color: #fff;
      background-color: #5FD577;
      text-decoration: none;
      padding: .5rem 1rem;
      border: 1px solid #5FD577;
      border-radius: .5rem;
      transition: .3s;
      margin-right: .5rem;

      &:hover {
        background-color: #fff;
        color: #5FD577;
      }
    }
  }
}

</style>
