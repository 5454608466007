<template>
  <div class="world__food">
    <img class="world__food-img-desktop" :src="background.image['web']" alt="">
    <img class="world__food-img-mobile" :src="background.image.mobile" alt="">
  </div>
</template>

<script>
import {imagePathMaker} from "@/utils/imagePathMaker";
export default {
  name: "WorldFoodSection",
  props: {
    background: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    imagePathMaker
  }
}
</script>

<style lang="scss" scoped>

.world__food {
  &-img-desktop {
    display: block;
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: top;
  }

  &-img-mobile {
    width: 100%;
    object-fit: contain;
    display: none;
  }
}

@media only screen and (max-width: 575px) {

  .world__food {
    &-img-desktop {
      display: none;
    }

    &-img-mobile {
      display: block;
    }
  }

}

</style>
