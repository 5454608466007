<template>
  <div class="position-relative">
    <!--  Intro Section  -->
    <div class="intro__bg">

      <img src="../assets/images/new-left-apple.png" alt="" class="left__apple">
<!--      <img src="../assets/images/new-right-cherry.png" alt="" class="mandarin">-->

      <div class="container">
        <div class="intro__block">
          <h1 v-html="getPropertyLang(intro.title)"></h1>
          <p>{{ getPropertyLang(intro.description) }}</p>
          <a href="#form-section" class="form__btn">{{ $t('submit_form_btn') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import {getPropertyLang} from "@/utils/getPropertyLang";

export default {
  name: "IntroSection",
  components: {
    TheHeader
  },

  props: {
    intro: {
      type: Object,
      default: () => ({})
    },

    navbar: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getPropertyLang
  }
}
</script>

<style lang="scss" scoped>

.intro__bg {
  //position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  //height: 675px;
  background-image: url("@/assets/images/intro-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .left__apple {
    position: absolute;
    left: 0;
    top: 12%;
  }

  .right__apple {
    position: absolute;
    right: 0;
    top: 12%;
  }

  .mandarin {
    position: absolute;
    right: 0;
    top: 60%;
  }

  .intro__block {
    position: absolute;
    //padding-top: 170px;
    //padding-bottom: 170px;
    top: 50%;
    transform: translateY(-50%);

    h1 {
      font-size: 60px;
      font-weight: 700;
      line-height: 72px;
      color: #1e1e1e;
      margin-bottom: 12px;
      width: 40%;

      ::v-deep strong {
        color: #03262c !important;
      }
    }

    p {
      font-size: 21px;
      line-height: 22px;
      font-weight: 500;
      color: #696969;
      margin-bottom: 60px;
    }

    .form__btn {
      text-decoration: none;
      padding: 20px 40px;
      background-color: #03262c;
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      border: none;
      border-radius: 32px;
      filter: drop-shadow(0px 12px 20px rgba(95, 213, 119, 0.251));
    }
  }
}

@media only screen and (max-width: 1399px) {
  .intro__block {
    h1 {
      width: 47% !important;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .intro__block {
    h1 {
      width: 57% !important;
    }
  }
}

@media only screen and (max-width: 991px) {
  .intro__bg {
    background-position: 70%;
  }

  .intro__block {
    h1 {
      width: 75% !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .intro__bg {
    .intro__block {
      h1 {
        font-size: 50px;
        line-height: 60px;
        width: 82% !important;
      }

      p {
        margin-bottom: 40px;
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .intro__bg {
    .intro__block {
      h1 {
        font-size: 37px;
        line-height: 50px;
        width: 97% !important;
      }
    }
  }
}

</style>
