<template>
  <div id="app">

    <TheHeader
      :navbar="response.settings"
    />

    <router-view
      :response="response"
    />

  </div>
</template>

<script>

import TheHeader from "@/components/TheHeader.vue";
import axios from "axios";

export default {
  name: 'App',
  components: {
    TheHeader
  },
  data() {
    return {
      response: null,
    }
  },
  async created() {
    await this.getAllInfo()
    this.setDynamicFavicon()
  },

  methods: {
    async getAllInfo() {
      try {
        const {data} = await axios.get('https://api.eximagro.uz/api')
        if (data) {
          this.response = data
          this.setDynamicFavicon(data.settings.icon)
        }
      } catch (e) {
        console.error(e)
      }
    },

    setDynamicFavicon(image) {
      if (image) {
        const favicon = document.getElementById('favicon')
        favicon.href = image
      }
    },
  }
}
</script>

<style lang="scss">
* {
  font-family: Museo, sans-serif;
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

.btn {
  &:active {
    background: none !important;
  }

  &:focus {
    box-shadow: none !important;
    background: none !important;
  }

  &:focus-visible {
    outline: none !important;
  }
}

.modal-header {
  display: none !important;
  border-bottom: none !important;
}

@media only screen and (max-width: 575px) {

  .modal-body {
    padding: 4rem 1rem 1rem !important;
  }
}
</style>
