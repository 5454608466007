<template>
  <div class="news">
    <div class="container">
      <b-breadcrumb
          :items="breadCrumb"
      />
      <h3>{{ getPropertyLang(oneNews['title']) }}</h3>
      <p class="news__created">{{ oneNews['created_at'] }}</p>
      <img :src="oneNews.image" alt="">
      <p class="mt-4">{{ getPropertyLang(oneNews.description) }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {getPropertyLang} from "@/utils/getPropertyLang";
import {imagePathMaker} from "@/utils/imagePathMaker";

export default {
  name: "id",
  data() {
    return {
      oneNews: {},
      breadCrumb: [
        {
          text: this.$t('main_page'),
          to: '/'
        },
        {
          text: this.$t('news_title'),
          to: '/news'
        },
        {
          text: '',
          to: '',
          active: true
        },
      ]
    }
  },

  async mounted() {
    await this.getOneNews()
  },

  methods: {
    getPropertyLang,
    imagePathMaker,
    getOneNews() {
      const {id} = this.$route.params
      axios.get(`https://api.eximagro.uz/api/article/${id}`)
          .then(res => {
            this.oneNews = res.data
            this.breadCrumb[2].text = this.getPropertyLang(this.oneNews.title)
          })
          .catch(error => {
            console.error(error)
          })

    }
  }
}
</script>

<style lang="scss" scoped>
.news {
  padding: 100px 0 40px;
  background-color: #ffffff;
  height: 100vh;

  & a {
    text-decoration: none;
    color: #5FD577;
  }

  & img {
    height: 500px;
    width: 100%;
    object-fit: cover;
  }

  & p {
    font-size: 1.1rem;
  }

  &__created {
    color: #999999;
    font-size: 1rem !important;
  }

}
</style>
