<template>
  <div class="gallery">
    <div class="container-fluid">
      <h2 class="gallery__title">{{ $t('gallery_title') }}</h2>
      <div class="gallery__slider">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide
            v-for="item in gallery"
          >
            <img :src="item.image" alt="">
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: "GallerySection",
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    gallery: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        loop: true,
        // autoplay: {
        //   delay: 2000
        // },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          },
          1400: {
            slidesPerView: 4,
          }
        }
      },
    }
  },

  methods: {
    imagePathMaker(image) {
      return process.env.VUE_APP_URL + image
    }
  }
}
</script>

<style lang="scss" scoped>

.gallery {
  padding: 60px 0 70px;

  &__title {
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    color: #1E1E1E;
  }

  ::v-deep .swiper-wrapper {
    display: flex !important;
    align-items: center !important;
  }

  .swiper-slide {
    height: 300px;
    width: 100%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .swiper-button-prev {
    background-image: url("../assets/images/slider-arrow-left.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 37px;
    height: 20px;
    transform: translateY(50%);
  }

  .swiper-button-prev::after {
    display: none;
  }

  .swiper-button-next {
    background-image: url("../assets/images/slider-right-arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 37px;
    height: 20px;
    transform: translateY(50%);
  }

  .swiper-button-next::after {
    display: none;
  }

}

@media only screen and (max-width: 767px) {

  .gallery {
    padding: 40px 0;

    .swiper-slide {
      height: 200px;
    }
  }

}

</style>
